<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('account.withdraw') }}</h4>
        </div>
        <div v-if="info.open" v-loading="loading" class="p-20">
            <el-form
                ref="ruleForm"
                class="input-form mx-auto"
                :model="formData"
                :show-message="false"
                :status-icon="true"
                :rules="rules"
            >
                <el-form-item>
                    <div class="d-flex-center">
                        <label class="mr-auto">{{ $t('wallet.sc') }}</label>
                        <el-select v-model="ci" prefix-icon="el-icon-money" :placeholder="$t('wallet.sc')" @change="onCoinChange()">
                            <el-option v-for="(item, index) in coinList" :key="index" :label="item" :value="index" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="d-flex-center">
                        <label class="mr-auto">{{ $t('available') }}</label>
                        <span class="ml-5">{{ info.balance }}</span>
                        <span class="ml-5">{{ coinList[ci] }}</span>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="d-flex-center">
                        <label class="mr-auto">{{ $t('wallet.sb') }}</label>
                        <el-select v-model="formData.network" prefix-icon="el-icon-money" :placeholder="$t('wallet.sb')">
                            <el-option v-for="(item, index) in info.networks" :key="index" :label="item.tag" :value="item.name" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item prop="address">
                    <el-input v-model="formData.address" prefix-icon="el-icon-link" :placeholder="$t('withdraw.epwa')" />
                </el-form-item>
                <el-form-item prop="amount">
                    <div class="d-flex-center">
                        <el-input v-model="formData.amount" prefix-icon="el-icon-money" :placeholder="$t('amount')">
                            <span slot="append" class="mx-5">{{ $t('fee') }} {{ info.fee_rate }}%</span>
                        </el-input>
                        <el-button type="primary" plain @click="setMax()">{{ $t('MAX') }}</el-button>
                    </div>
                </el-form-item>
                <el-form-item prop="email_captcha">
                    <div class="d-flex-center">
                        <el-input v-model="formData.email_captcha" prefix-icon="el-icon-unlock" :placeholder="$t('user.email_captcha')" />
                        <el-button type="primary" plain @click="sendEmailCaptcha()">{{ $t('send') }}</el-button>
                    </div>
                </el-form-item>
                <el-form-item class="mt-30">
                    <el-button type="primary" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitForm">{{ $t('submit') }}</el-button>
                </el-form-item>
            </el-form>
            <div class="font-12 color-gray text-center px-20 mx-20 border-top">
                <p>
					For the safety of users' funds, the platform may call to confirm your operation of withdrawing currency, please pay attention to answering;
				</p>
				<p>
					Withdrawable amount ≤ account available assets - unrecognized digital assets;
				</p>	
				<p>
					The handling fee of network transfer is not fixed, and it shall be adjusted according to the congestion of the whole network;
				</p>
            </div>
        </div>
        <div v-else-if="!loadingData">
            <h2 class="text-center">{{ $route.params.coin }} Forbidden to move in</h2>
        </div>
        <div v-if="info.open">
            <div class="d-flex-center border-bottom border-top">
                <h4 class="mr-auto color-333">{{ $t('withdraw.history.title') }}</h4>
                <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('refresh') }}</el-button>
            </div>
            <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 border-bottom font-12">
                <el-col :span='5'>{{ $t('order_no') }}</el-col>
                <el-col :span='5'>{{ $t('amount') }}</el-col>
                <el-col :span='5'>{{ $t('withdraw.st') }}</el-col>
                <el-col :span='5'>{{ $t('date_time') }} / {{ $t('remark') }}</el-col>
                <el-col class="text-right" :span='4'>{{ $t('status') }}</el-col>
            </el-row>
            <div v-loading="loadingRecord">
                <div v-if="list.data.length">
                    <el-row v-for="(item, index) in list.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="px-20 py-15 border-bottom font-14">
                        <el-col :span='5'>{{ item.order_id }}</el-col>
                        <el-col :span='5'>{{ item.amount }}</el-col>
                        <el-col :span='5'>{{ item.create_time }}</el-col>
                        <el-col :span='5'>{{ item.status == 1 ? item.done_time : item.remark }}</el-col>
                        <el-col class="text-right" :span='4'>
                            <el-tag :type="status[item.status][0]" effect="plain" hit>{{ status[item.status][1] }}</el-tag>
                        </el-col>
                    </el-row>
                    <div class="d-flex-center pt-20">
                        <el-pagination
                            :hide-on-single-page="true"
                            background
                            layout="prev, pager, next"
                            :page-size="list.per_page"
                            :current-page="list.current_page"
                            :total="list.total"
                            @current-change="onPage"
                        />
                    </div>
                </div>
                <el-empty v-else :image-size="100" description="No Data"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import coinLogo from '@/assets/coin/logo'
import { float as regexpFloat } from '@/services/regexp'
export default {
    data() {
        return {
            coinLogo,
            ci: 0,
            coinList: ['USDT','BTC','ETH'],
            info: {
                open: false,
                networks: [],
                nl: 0,
                balance: '0.000000',
                fee_rate: 0,
                address: []
            },
            loadingData: true,
            loadingRecord: false,
            loading: false,
            formData: {
                network: 'ethereum',
                coin: this.$route.params.coin,
                amount: null,
                address_id: null,
                email_captcha: null
            },
            query: { ...this.$route.query },
            list: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            status: [
                ['Pending','primary'],
                ['Pass','primary'],
                ['Pass','primary'],
                ['Success','success'],
                ['Failed','danger']
            ],
            timer: null,
            rules: {
                amount: [
                    { required: true, pattern: regexpFloat, trigger: 'blur' }
                ],
                address_id: [
                    { required: true, type: 'integer', trigger: 'blur' }
                ],
                address: [
                    { required: true, type: 'string', min: 6, trigger: 'blur' }
                ],
                email_captcha: [
                    { required: true, type: 'string', min: 5, trigger: 'blur' }
                ]
            }
        }
    },
    created: function() {
        const coin = this.$route.params.coin.toUpperCase()
        const ci = this.coinList.indexOf(coin)
        if(ci >= 0){
            this.ci = ci
        }
        this.fatchData()
        this.fatchRecord()
    },
    methods: {
        onCoinChange: function(){
            this.fatchData()
            this.$nav.push('/wallet/withdraw/' + this.coinList[this.ci])
        },
        setMax: function(){
            this.formData.amount = this.info.balance <= 0 ? '' : this.info.balance * 1
        },
        sendEmailCaptcha: async function (){
            this.loadingData = true
            try{
                await this.$request.get('user/email_captcha')
            }catch(e){
                //TODO handle the exception
            }
            this.loadingData = false
        },
        fatchData: async function () {
            this.loadingData = true
            try {
                const { data } = await this.$request.post('withdraw/info/coin/'+this.coinList[this.ci], this.query)
                this.info = data
                this.formData.network = data.networks[data.ni].name
            } catch (error) {
                //
            }
            this.loadingData = false
        },
        submitForm: async function () {
            try {
                await this.$refs.ruleForm.validate()
                this.loading = true
                await this.$request.post('withdraw', this.formData)
                this.formData.amount = null
                this.formData.address = null 
                this.formData.email_captcha = null 
                this.fatchRecord()
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        fatchRecord: async function () {
            this.loadingRecord = true
            try {
                const { data } = await this.$request.post('withdraw/log_pc', this.query)
                this.list = data
            } catch (error) {
                //
            }
            this.loadingRecord = false
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style lang="scss" scoped>
.input-form{
    width: 480px;
}
.voucher-img{
    width: 360px;
    height: 270px;
}
</style>